import axios from 'axios'

export default {
    getMonths : () => axios.post('graphs/get-months', {}),
    getCedis : () => axios.post('graphs/get-cedis', {}),

    getSellersWithSales: (month, brand) => axios.post('graphs/get-seller-with-sales', {month: month, brand: brand}),
    getCustomersReached: (month, brand) => axios.post('graphs/get-customers-reached', {month: month, brand: brand}),
    getMonthSales: (month, brand) => axios.post('graphs/get-month-sales', {month: month, brand: brand}),
    getAvailableInvestment: (month, brand) => axios.post('graphs/get-available-investment', {month: month, brand: brand}),
    getCedisPerformance: (month, brand) => axios.post('graphs/get-cedis-performance', {month: month, brand: brand}),
    getMonthsPerformance: (month, brand) => axios.post('graphs/get-months-performance', {month: month, brand: brand}),
    getProjectionSales: (month, brand) => axios.post('graphs/get-projection-sales', {month: month, brand: brand}),
    getMonthsSalesCedis: (month, brand,cedis) => axios.post('graphs/get-sales-cedis', {month: month, brand: brand, cedis: cedis}),

    getSalesVsTarget: (month, brand) => axios.post('graphs/get-sales-vs-target', {month: month, brand: brand}),
    getSalesByCedis: (month, brand) => axios.post('graphs/get-sales-by-cedis', {month: month, brand: brand}),
    getCustomersDistribution: (month, brand, cedis) => axios.post('graphs/get-customers-distribution', {month: month, brand: brand, cedis: cedis}),
    getCedisCustomersPerformance: (month, brand, cedis) => axios.post('graphs/get-cedis-customers-performance', {month: month, brand: brand, cedis: cedis}),

    //Challenges
    getChallengeData : (challenge_id) => axios.post('graphs/get-challenge-data', {challenge_id: challenge_id}),
    getChallengeSellersWithSales: (challenge_id) => axios.post('graphs/get-challenge-seller-with-sales', {challenge_id: challenge_id}),
    getChallengeCustomersReached: (challenge_id) => axios.post('graphs/get-challenge-customers-reached', {challenge_id: challenge_id}),
    getChallengeCedisPerformance: (challenge_id) => axios.post('graphs/get-challenge-cedis-performance', {challenge_id: challenge_id}),
    getChallengeParticipation: (challenge_id, type) => axios.post('graphs/get-challenge-participation', {challenge_id: challenge_id, type: type}),
}